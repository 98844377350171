<template>
  <div>
    <v-card flat>
      <v-card-text class="py-0">
        <v-menu
          bottom
          offset-y
          :close-on-content-click="false"
          :key="updateFilterKey + 1"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :disabled="loadingUsers"
              :key="updateFilterKey"
              v-on="on"
              v-bind="attrs"
              style="
                background-color: hsla(0, 0%, 100%, 0.2) !important;
                border: none;
              "
              :class="selectedUserDash ? 'mr-2 px-0' : 'pl-1 mr-2'"
            >
              <v-avatar
                style="
                  height: 32px !important;
                  width: 32px !important;
                  min-width: 32px !important;
                "
                v-if="selectedUserDash && !loadingUsers"
                :color="selectedUserDash.avatar ? 'white' : 'secondary'"
                class="mr-2"
              >
                <v-img
                  v-if="selectedUserDash.avatar"
                  contain
                  :src="selectedUserDash.avatar"
                ></v-img>
                <v-icon v-if="!selectedUserDash.avatar">person</v-icon>
              </v-avatar>
              <v-icon
                v-else-if="!selectedUserDash && !loadingUsers"
                v-on="on"
                class="mr-1"
                >person</v-icon
              >
              <v-progress-circular
                v-else-if="loadingUsers"
                indeterminate
                :size="20"
                class="mr-1"
              ></v-progress-circular>
              <span v-if="selectedUserDash">
                {{ selectedUserDash.firstname }} {{ selectedUserDash.surname }}
                <v-btn small icon @click="clearUser"
                  ><v-icon color="redPop" small>close</v-icon></v-btn
                >
              </span>
              <span v-else>Select User</span>
            </v-chip>
          </template>
          <v-card color="greyBase">
            <v-card-text class="pa-2">
              <v-text-field
                v-model="searchUsers"
                prepend-inner="search"
                dense
                hide-details
                outlined
                label="Search Users"
                style="width: 275px"
              >
              </v-text-field>
              <div style="max-height: 40vh; overflow-y: scroll">
                <v-list dense v-if="usersFiltered.length > 0">
                  <v-list-item
                    dense
                    v-for="(user, index) in usersFiltered"
                    :key="index"
                    @click="changeUser(user)"
                    style="width: 275px"
                  >
                    <v-list-item-avatar>
                      <v-avatar color="secondary" size="32">
                        <span v-if="!user.avatar" class="text-h7">{{
                          $Format.avatarAbbreviation(user.firstname)
                        }}</span>
                        <img
                          v-else
                          :src="user.avatar"
                          referrerpolicy="no-referrer"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        user.firstname + " " + user.surname
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        user.emailAddress
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu
          bottom
          offset-y
          :close-on-content-click="false"
          :key="updateCustomerKey"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :disabled="loadingRelatedOrganisations"
              v-on="on"
              v-bind="attrs"
              style="
                background-color: hsla(0, 0%, 100%, 0.2) !important;
                border: none;
              "
              :class="selectedCustomer ? 'mr-2 px-0' : 'pl-1 mr-2'"
            >
              <v-avatar
                style="
                  height: 32px !important;
                  width: 32px !important;
                  min-width: 32px !important;
                "
                v-if="
                  filter.customerId &&
                  selectedCustomer &&
                  !loadingRelatedOrganisations
                "
                color="white"
                class="mr-2"
              >
                <v-img
                  v-if="selectedCustomer.relatedOrganisation.logo"
                  contain
                  :src="selectedCustomer.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else style="color: var(--v-greyBase-base) !important">
                  {{ selectedCustomer.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
              <v-icon
                class="mr-1"
                v-if="
                  !filter.customerId &&
                  !selectedCustomer &&
                  !loadingRelatedOrganisations
                "
                >handshake</v-icon
              >
              <v-progress-circular
                v-else-if="loadingRelatedOrganisations"
                indeterminate
                :size="20"
                class="mr-1"
              ></v-progress-circular>
              <span
                v-if="
                  filter.customerId &&
                  selectedCustomer &&
                  selectedCustomer.relatedOrganisation
                "
              >
                {{
                  $Format.removePtyLtd(
                    selectedCustomer.relatedOrganisation.name
                  )
                }}
                <v-btn small icon @click="changeCustomer(null)"
                  ><v-icon color="redPop" small>close</v-icon></v-btn
                >
              </span>
              <span v-else>Select Customer</span>
            </v-chip>
          </template>
          <v-card color="greyBase">
            <v-card-text class="pa-2">
              <v-text-field
                v-model="searchCustomers"
                prepend-inner="search"
                dense
                hide-details
                outlined
                label="Search Customers"
                style="width: 275px"
              >
              </v-text-field>
              <div style="max-height: 40vh; overflow-y: scroll">
                <v-list dense v-if="filterCustomers.length > 0">
                  <v-list-item
                    dense
                    v-for="(customer, index) in filterCustomers"
                    :key="index"
                    @click="changeCustomer(customer)"
                    style="width: 275px"
                  >
                    <v-list-item-avatar>
                      <v-avatar color="secondary" size="32">
                        <span
                          v-if="!customer.relatedOrganisation.logo"
                          class="text-h7"
                          >{{
                            $Format.avatarAbbreviation(
                              customer.relatedOrganisation.name
                            )
                          }}</span
                        >
                        <img
                          v-else
                          :src="customer.relatedOrganisation.logo"
                          referrerpolicy="no-referrer"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        customer.relatedOrganisation.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="customer.relatedOrganisation.alias"
                      >
                        {{ customer.relatedOrganisation.alias }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu
          bottom
          offset-y
          :close-on-content-click="false"
          :key="updateShipperKey"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :disabled="loadingShippers"
              v-on="on"
              v-bind="attrs"
              style="
                background-color: hsla(0, 0%, 100%, 0.2) !important;
                border: none;
              "
              :class="selectedShipper ? 'mr-2 px-0' : 'pl-1 mr-2'"
            >
              <v-avatar
                style="
                  height: 32px !important;
                  width: 32px !important;
                  min-width: 32px !important;
                "
                v-if="filter.shipperId && selectedShipper && !loadingShippers"
                color="white"
                class="mr-2"
              >
                <v-img
                  v-if="selectedShipper.logo"
                  contain
                  :src="selectedShipper.logo"
                ></v-img>
                <h3 v-else style="color: var(--v-greyBase-base) !important">
                  {{ selectedShipper.name.charAt(0) }}
                </h3>
              </v-avatar>
              <v-icon
                class="mr-1"
                v-if="!filter.shipperId && !selectedShipper && !loadingShippers"
                >north_east</v-icon
              >
              <v-progress-circular
                v-else-if="loadingShippers"
                indeterminate
                :size="20"
                class="mr-1"
              ></v-progress-circular>
              <span v-if="filter.shipperId && selectedShipper">
                {{ $Format.removePtyLtd(selectedShipper.name) }}
                <v-btn small icon @click="changeShipper(null)"
                  ><v-icon color="redPop" small>close</v-icon></v-btn
                >
              </span>
              <span v-else>Select Shipper</span>
            </v-chip>
          </template>
          <v-card color="greyBase">
            <v-card-text class="pa-2">
              <v-text-field
                v-model="searchShippers"
                prepend-inner="search"
                dense
                hide-details
                outlined
                label="Search Shippers"
                style="width: 275px"
              >
              </v-text-field>
              <div style="max-height: 40vh; overflow-y: scroll">
                <v-list dense v-if="filterShippers.length > 0">
                  <v-list-item
                    dense
                    v-for="(shipper, index) in filterShippers"
                    :key="index"
                    @click="changeShipper(shipper)"
                    style="width: 275px"
                  >
                    <v-list-item-avatar>
                      <v-avatar color="secondary" size="32">
                        <span v-if="!shipper.logo" class="text-h7">{{
                          $Format.avatarAbbreviation(shipper.name)
                        }}</span>
                        <img
                          v-else
                          :src="shipper.logo"
                          referrerpolicy="no-referrer"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ shipper.name }}</v-list-item-title>
                      <v-list-item-subtitle v-if="shipper.alias">
                        {{ shipper.alias }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu
          bottom
          offset-y
          :close-on-content-click="false"
          :key="updateVesselKey"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :disabled="loadingActiveVessels"
              v-on="on"
              v-bind="attrs"
              style="
                background-color: hsla(0, 0%, 100%, 0.2) !important;
                border: none;
              "
              :class="
                filter.vesselVoyage?.name ? 'mr-2 pl-1 pr-0' : 'mr-2 pl-1'
              "
            >
              <v-icon class="mr-1" v-if="!loadingActiveVessels"
                >directions_boat</v-icon
              >
              <v-progress-circular
                v-else-if="loadingActiveVessels"
                indeterminate
                :size="20"
                class="mr-1"
              ></v-progress-circular>
              <span v-if="filter.vesselVoyage">
                {{ filter.vesselVoyage.name }} {{ filter.vesselVoyage.voyage }}
                <v-btn small icon @click="changeVoyage(null)"
                  ><v-icon color="redPop" small>close</v-icon></v-btn
                >
              </span>
              <span v-else>Select Vessel-Voyage</span>
            </v-chip>
          </template>
          <v-card color="greyBase">
            <v-card-text class="pa-2">
              <v-text-field
                v-model="searchVessels"
                prepend-inner="search"
                dense
                hide-details
                outlined
                label="Search Vessel/Voyage"
                style="width: 275px"
              >
              </v-text-field>
              <div style="max-height: 40vh; overflow-y: scroll">
                <v-list dense v-if="filterVesselVoyage.length > 0">
                  <v-list-item
                    dense
                    v-for="(vessel, index) in filterVesselVoyage"
                    :key="index"
                    @click="changeVoyage(vessel)"
                    style="width: 275px"
                  >
                    <v-list-item-avatar>
                      <v-avatar color="secondary" size="32">
                        <v-icon>directions_boat</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ vessel.name }}
                        {{ vessel.voyage }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu
          bottom
          offset-y
          :close-on-content-click="false"
          :key="updateTeamKey"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :disabled="loadingTeams"
              v-on="on"
              v-bind="attrs"
              class="py-1 mr-2"
              :class="filter.teams.length > 0 ? 'pl-1 pr-0' : 'pl-1'"
              style="background-color: hsla(0, 0%, 100%, 0.2) !important"
            >
              <v-icon
                v-if="!loadingTeams"
                :class="
                  filter.teams.length == teams.length ||
                  filter.teams.length == 0
                    ? 'mr-2'
                    : 'mr-4'
                "
                >groups</v-icon
              >
              <v-progress-circular
                v-if="loadingTeams"
                indeterminate
                :size="20"
                class="mr-2"
              ></v-progress-circular>
              <v-chip
                v-if="filter.teams.length == teams.length && teams.length > 0"
                color="edit"
              >
                All Teams
              </v-chip>
              <span v-else-if="filter.teams.length == 0"> Select Teams </span>
              <v-chip
                v-else
                v-for="(team, index) in filter.teams"
                :key="index"
                :color="teams.find((x) => x.id == team).colour"
                :style="{ 'z-index': 100 - index, 'margin-left': '-10px' }"
                style="border: 1px solid hsla(0, 0%, 100%, 0.4)"
              >
                {{ teams.find((x) => x.id == team).name }}
              </v-chip>
            </v-chip>
          </template>
          <v-card color="greyBase">
            <v-card-text class="pa-2">
              <v-list>
                <v-list-item
                  v-for="(team, i) in teams"
                  :key="i"
                  :input-value="
                    filter.teams.find((x) => x == team.id) ? true : false
                  "
                  active-class=" text--accent-4"
                  @click="addTeamToFilter(team)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ team.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{
                      filter.teams.find((x) => x == team.id)
                        ? "check_box"
                        : "check_box_outline_blank"
                    }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-row justify="center" align="center" class="my-2">
          <v-col cols="12">
            <DocumentDashboard
              :activeWeeks="activeWeeks"
              :filter="filter"
              :filterKey="weekKey"
              @updateTotals="updateTotals"
              :loadingKey="filterKey"
              @updateShippers="updateShippers"
            />
          </v-col>
          <v-col cols="12" sm="7">
            <v-card
              flat
              :loading="loadingUserPerformance"
              style="
                border-radius: 20px;
                background: var(--v-greyRaised-base) !important;
              "
            >
              <v-card-title style="font-size: 16px"
                ><v-icon color="primary" class="mr-2"> insights </v-icon> User
                Analysis
                <v-btn class="ml-2" icon @click="getUserPerformance()" small
                  ><v-icon small>refresh</v-icon></v-btn
                ></v-card-title
              >
              <v-card-text>
                <v-list dense style="height: 400px; overflow-y: auto">
                  <v-list-item v-if="userPerformance.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">
                        No data available
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="user in userPerformance"
                    :key="user.userId"
                    style="border-bottom: 0.25px solid grey"
                  >
                    <v-list-item-action>
                      <v-avatar
                        :color="user.user.avatar ? 'white' : 'secondary'"
                        size="36"
                      >
                        <v-img
                          v-if="user.user.avatar"
                          :src="user.user.avatar"
                          contain
                        ></v-img>
                        <h3 v-else>{{ user.user.firstname.charAt(0) }}</h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content style="width: 200px">
                      <v-list-item-title>
                        {{ user.user.firstname }} {{ user.user.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px">
                        Open Files: {{ user.openFiles }} | Unprocessed:
                        {{ user.unprocessedFiles }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content
                      class="text-center"
                      style="width: 225px"
                    >
                      <v-btn-toggle
                        outlined
                        :light="!$store.state.darkMode"
                        rounded
                      >
                        <v-tooltip top open-delay="400">
                          <template v-slot:activator="{ on }">
                            <v-btn small outlined style="width: 60px" v-on="on">
                              <span
                                style="
                                  text-transform: none;
                                  font-size: 11px;
                                  color: var(--v-grey-base);
                                "
                                >{{ user?.TOTAL ?? 0 }}</span
                              >
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">Total Documents</span>
                        </v-tooltip>
                        <v-tooltip top open-delay="400">
                          <template v-slot:activator="{ on }">
                            <v-btn small outlined style="width: 60px" v-on="on">
                              <v-icon left small :color="'error'">error</v-icon>
                              <span
                                style="
                                  text-transform: none;
                                  font-size: 11px;
                                  color: var(--v-error-base);
                                "
                                >{{ user?.OVERDUE ?? 0 }}</span
                              >
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">Overdue Documents</span>
                        </v-tooltip>
                        <v-tooltip top open-delay="400">
                          <template v-slot:activator="{ on }">
                            <v-btn small outlined style="width: 60px" v-on="on">
                              <v-icon left small :color="'warning'"
                                >warning</v-icon
                              >
                              <span
                                style="
                                  text-transform: none;
                                  font-size: 11px;
                                  color: var(--v-warning-base);
                                "
                                >{{ user?.DANGER ?? 0 }}</span
                              >
                            </v-btn>
                          </template>
                          <span style="font-size: 11px"
                            >In Danger Documents</span
                          >
                        </v-tooltip>
                        <v-tooltip top open-delay="400">
                          <template v-slot:activator="{ on }">
                            <v-btn small outlined style="width: 60px" v-on="on">
                              <v-icon left small :color="'success'"
                                >check_circle</v-icon
                              >
                              <span
                                style="
                                  text-transform: none;
                                  font-size: 11px;
                                  color: var(--v-success-base);
                                "
                                >{{ user?.GOOD ?? 0 }}</span
                              >
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">Within KPI</span>
                        </v-tooltip>
                      </v-btn-toggle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn-toggle
                        outlined
                        :light="!$store.state.darkMode"
                        rounded
                      >
                        <v-tooltip top open-delay="400">
                          <template v-slot:activator="{ on }">
                            <v-btn small outlined v-on="on">
                              <v-icon
                                left
                                small
                                :color="
                                  user.performance > 50
                                    ? 'red'
                                    : user.performance > 30
                                    ? 'orange'
                                    : 'success'
                                "
                                >speed</v-icon
                              >
                              <span
                                style="font-size: 11px"
                                :class="
                                  user.performance > 50
                                    ? 'red--text ml-1'
                                    : user.performance > 30
                                    ? 'orange--text ml-1'
                                    : 'success--text ml-l'
                                "
                              >
                                {{ user.performance }}%
                              </span>
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">User Performance</span>
                        </v-tooltip>
                        <v-tooltip top open-delay="400">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              outlined
                              v-on="on"
                              style="text-transform: none"
                            >
                              <span
                                style="font-size: 11px"
                                :class="
                                  user.risk > 80
                                    ? 'red--text mr-2'
                                    : user.risk > 70
                                    ? 'orange--text mr-2'
                                    : 'success--text mr-2'
                                "
                              >
                                {{ user.risk }}% Risk
                              </span>
                              <v-progress-circular
                                right
                                :value="user.risk"
                                size="18"
                                :color="
                                  user.risk > 80
                                    ? 'red'
                                    : user.risk > 70
                                    ? 'orange'
                                    : 'success'
                                "
                              ></v-progress-circular>
                            </v-btn>
                          </template>
                          <span style="font-size: 11px">Risk Percentage</span>
                        </v-tooltip>
                      </v-btn-toggle>
                      <!-- <v-container>
                        <v-row justify="center" align="center">
                          <v-col cols="12" class="text-center my-0 py-0">
                            <v-chip style="border: none" outlined small 
                            :color="user.performance > 90? 'red': user.performance > 80?'orange':user.performance > 60?'blue':'success'">
                              <span
                                style="font-size: 12px"
                                :class="
                                  user.performance > 90
                                    ? 'red--text mr-2'
                                    : user.performance > 80
                                    ? 'orange--text mr-2'
                                    : user.performance > 60
                                    ? 'blue--text mr-2'
                                    : 'success--text mr-2'
                                "
                              >
                                {{ user.performance }}% Overdue
                              </span>
                              <v-icon right small>
                                speed
                              </v-icon>
                            </v-chip>
                          </v-col>
                          <v-col cols="12" class="text-center my-0 py-0">
                            <v-chip style="border: none" outlined small>
                              <span
                                style="font-size: 12px"
                                :class="
                                  user.risk > 90
                                    ? 'red--text mr-2'
                                    : user.risk > 80
                                    ? 'orange--text mr-2'
                                    : user.risk > 60
                                    ? 'blue--text mr-2'
                                    : 'success--text mr-2'
                                "
                              >
                                {{ user.scaledScore }}% Risk
                              </span>
                              <v-progress-circular
                                right
                                :value="user.risk"
                                size="18"
                                :color="
                                  user.risk > 90
                                    ? 'red'
                                    : user.risk > 80
                                    ? 'orange'
                                    : user.risk > 60
                                    ? 'blue'
                                    : 'success'
                                "
                              ></v-progress-circular>
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-container> -->
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="5">
            <v-card
              flat
              :loading="loadingUserPerformance"
              style="
                border-radius: 20px;
                background: var(--v-greyRaised-base) !important;
              "
            >
              <v-card-title style="font-size: 16px"
                ><v-icon color="primary" class="mr-2"> speed </v-icon> Overall
                Performance</v-card-title
              >
              <v-card-text>
                <v-list dense style="height: 400px; overflow-y: auto">
                  <v-list-item v-if="userPerformance.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">
                        No data available
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="userPerformance.length > 0">
                    <v-list-item-action>
                      <v-progress-circular
                        :value="overallMetrics.PERFORMANCE"
                        size="26"
                        :color="
                          overallMetrics.PERFORMANCE > 50
                            ? 'success'
                            : overallMetrics.PERFORMANCE > 40
                            ? 'orange'
                            : 'red'
                        "
                      ></v-progress-circular>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 16px">
                        Combined Performance
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip outlined style="border: none">
                        {{ overallMetrics.PERFORMANCE }}%
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="userPerformance.length > 0">
                    <v-list-item-action>
                      <v-progress-circular
                        :value="overallMetrics.WEIGHTED_RISK"
                        size="26"
                        :color="
                          overallMetrics.WEIGHTED_RISK > 90
                            ? 'red'
                            : overallMetrics.WEIGHTED_RISK > 80
                            ? 'orange'
                            : overallMetrics.WEIGHTED_RISK > 60
                            ? 'blue'
                            : 'success'
                        "
                      ></v-progress-circular>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 16px">
                        Combined Risk
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip outlined style="border: none">
                        {{ overallMetrics.WEIGHTED_RISK }}%
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import DocumentDashboard from "./DocumentationCard.vue";
export default {
  components: {
    DocumentDashboard,
  },
  data: () => ({
    activeVessels: [],
    activeWeeks: [],
    filter: {
      assignedUserId: null,
      customerId: null,
      shipperId: null,
      sailingWeek: null,
      teams: [],
      vesselVoyage: null,
    },
    selectedUserDash: null,
    selectedCustomer: null,
    selectedTeams: [],
    revealFilters: true,
    filterKey: 100,
    loadingRelatedOrganisations: false,
    loadingWeeks: false,
    loadingActiveVessels: false,
    loadingUserPerformance: false,
    loadingTeams: false,
    shippers: [],
    selectedShipper: null,
    loadingUsers: false,
    updateCustomerKey: 120,
    updateVesselKey: 130,
    updateTeamKey: 140,
    updateShipperKey: 150,
    loadingAll: false,
    loadingShippers: false,
    overallMetrics: {
      OVERDUE: 0,
      PERFORMANCE: 0,
      TOTAL: 0,
      RISK: 0,
      WEIGHTED_RISK: 0,
    },
    related: { data: [] },
    teams: [],
    updateFilterKey: 100,
    totals: {
      unprocessedFileTotal: 0,
      notReadyToInvoiceTotal: 0,
      documentationTotal: 0,
      openFilesTotal: 0,
    },
    totalRefreshKey: 200,
    users: [],
    userTimeout: undefined,
    userQuery: undefined,
    userPerformance: [],
    weekQuery: undefined,
    weekTimeout: undefined,
    weekKey: 300,
    searchCustomers: "",
    searchUsers: "",
    searchVessels: "",
    searchShippers: "",
  }),
  mounted() {
    this.getActiveWeeks();
    this.getUserPerformance();
  },
  watch: {
    filterKey() {
      this.getActiveWeeks();
      this.getUserPerformance();
    },
  },
  computed: {
    filterCustomers() {
      let result = this.related.data;
      if (result.length > 0) {
        if (this.filter.teams.length > 0) {
          result = result.filter(
            (x) =>
              x.relationshipTeams &&
              x.relationshipTeams.some((y) =>
                this.filter.teams.includes(y.teamId)
              )
          );
        }
        if (this.searchCustomers) {
          result = result.filter(
            (customer) =>
              customer.relatedOrganisation.name
                .toLowerCase()
                .includes(this.searchCustomers.toLowerCase()) ||
              customer.relatedOrganisation.alias
                ?.toLowerCase()
                .includes(this.searchCustomers.toLowerCase())
          );
        }
      }
      return result;
    },
    filterShippers() {
      let result = this.shippers;
      if (result.length > 0) {
        if (this.searchShippers) {
          result = result.filter((shipper) =>
            shipper.name
              .toLowerCase()
              .includes(this.searchShippers.toLowerCase())
          );
        }
      }
      return result;
    },
    usersFiltered() {
      let users = [];
      if (this.users.length > 0) {
        if (this.searchUsers) {
          users = this.users.filter(
            (user) =>
              user.firstname
                .toLowerCase()
                .includes(this.searchUsers.toLowerCase()) ||
              user.surname
                .toLowerCase()
                .includes(this.searchUsers.toLowerCase()) ||
              user.emailAddress
                .toLowerCase()
                .includes(this.searchUsers.toLowerCase())
          );
        } else {
          users = this.users;
        }
      }
      return users;
    },
    filterVesselVoyage() {
      let result = this.activeVessels;
      if (result.length > 0) {
        if (this.searchVessels) {
          result = result.filter(
            (vessel) =>
              vessel.name
                .toLowerCase()
                .includes(this.searchVessels.toLowerCase()) ||
              vessel.voyage
                .toLowerCase()
                .includes(this.searchVessels.toLowerCase())
          );
        }
      }
      return result;
    },
  },
  created() {
    if (sessionStorage.getItem(`users_${this.$store.state.currentOrg.id}`)) {
      this.users = JSON.parse(
        sessionStorage.getItem(`users_${this.$store.state.currentOrg.id}`)
      );
    }
    if (sessionStorage.getItem(`teams_${this.$store.state.currentOrg.id}`)) {
      this.teams = JSON.parse(
        sessionStorage.getItem(`teams_${this.$store.state.currentOrg.id}`)
      );
    }
    if (
      sessionStorage.getItem(`activeVessels_${this.$store.state.currentOrg.id}`)
    ) {
      this.activeVessels = JSON.parse(
        sessionStorage.getItem(
          "activeVessels_" + this.$store.state.currentOrg.id
        )
      );
    }
    if (
      sessionStorage.getItem(
        `relatedOrganisations_${this.$store.state.currentOrg.id}`
      )
    ) {
      this.related = JSON.parse(
        sessionStorage.getItem(
          "relatedOrganisations_" + this.$store.state.currentOrg.id
        )
      );
    }
    if (sessionStorage.getItem(`shippers_${this.$store.state.currentOrg.id}`)) {
      this.shippers = JSON.parse(
        sessionStorage.getItem("shippers_" + this.$store.state.currentOrg.id)
      );
    }
    if (sessionStorage.getItem("selectedUserDash")) {
      this.selectedUserDash = JSON.parse(
        sessionStorage.getItem("selectedUserDash")
      );
      this.filter.assignedUserId = this.selectedUserDash.id;
    }
    if (sessionStorage.getItem("selectedShipper")) {
      this.selectedShipper = JSON.parse(
        sessionStorage.getItem("selectedShipper")
      );
      this.filter.shipperId = this.selectedShipper.id;
    }
    if (sessionStorage.getItem("selectedCustomer")) {
      this.selectedCustomer = JSON.parse(
        sessionStorage.getItem("selectedCustomer")
      );
      this.filter.customerId = this.selectedCustomer.relatedOrganisation.id;
    }
    if (sessionStorage.getItem("selectedVessel")) {
      this.filter.vesselVoyage = JSON.parse(
        sessionStorage.getItem("selectedVessel")
      );
    }
    if (sessionStorage.getItem("selectedTeams")) {
      this.selectedTeams = JSON.parse(sessionStorage.getItem("selectedTeams"));
      this.filter.teams = this.selectedTeams.map((x) => x.id);
    }
    this.getTeams();
    this.getUsers();
    this.loadingShippers = true;
    this.getActiveWeeks();
    this.getActiveVessels();
    this.getRelations();
  },
  methods: {
    updateShippers(shippers) {
      this.shippers = shippers;
      sessionStorage.setItem(
        "shippers_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.shippers)
      );
      this.loadingShippers = false;
    },
    clearUser() {
      this.selectedUserDash = null;
      this.filter.assignedUserId = null;
      sessionStorage.setItem(
        "selectedUserDash",
        JSON.stringify(this.selectedUserDash)
      );
      this.updateFilterKey++;
      this.filterKey++;
    },

    changeUser(user) {
      this.selectedUserDash = user;
      this.filter.assignedUserId = user.id;
      sessionStorage.setItem(
        "selectedUserDash",
        JSON.stringify(this.selectedUserDash)
      );
      this.updateFilterKey++;
      this.filterKey++;
    },
    changeCustomer(customer) {
      this.selectedCustomer = customer;
      this.filter.customerId = customer?.relatedOrganisation?.id
        ? customer.relatedOrganisation.id
        : null;
      sessionStorage.setItem(
        "selectedCustomer",
        JSON.stringify(this.selectedCustomer)
      );
      this.updateCustomerKey++;
      this.filterKey++;
    },
    changeShipper(shipper) {
      this.selectedShipper = shipper;
      this.filter.shipperId = shipper ? shipper.id : null;
      sessionStorage.setItem(
        "selectedShipper",
        JSON.stringify(this.selectedShipper)
      );
      this.updateShipperKey++;
      this.filterKey++;
    },
    changeVoyage(vessel) {
      this.filter.vesselVoyage = vessel;
      this.updateVesselKey++;
      sessionStorage.setItem(
        "selectedVessel",
        JSON.stringify(this.filter.vesselVoyage)
      );
      this.filterKey++;
    },
    addTeamToFilter(team) {
      if (this.filter.teams.includes(team.id)) {
        this.filter.teams = this.filter.teams.filter((x) => x != team.id);
        this.selectedTeams = this.selectedTeams.filter((x) => x.id != team.id);
      } else {
        this.filter.teams.push(team.id);
        this.selectedTeams.push(team);
      }
      sessionStorage.setItem(
        "selectedTeams",
        JSON.stringify(this.selectedTeams)
      );
      this.filterKey++;
    },
    async getActiveWeeks() {
      //   this.loadingActiveWeeks = true;
      if (this.loadingWeeks) {
        clearTimeout(this.weekTimeout);
      }
      if (this.loadingWeeks && this.weekQuery) {
        this.weekQuery.abort();
      }
      this.loadingWeeks = true;
      let filterFinal = null;
      // let {vesselVoyage, ...filterVessel} = this.filter
      if (
        this.filter &&
        this.filter.vesselVoyage &&
        this.filter.vesselVoyage?.voyage
      ) {
        filterFinal = {
          ...this.filter,
          vesselVoyage: {
            voyage: this.filter.vesselVoyage.voyage,
            id: this.filter.vesselVoyage.id,
          },
        };
      } else {
        filterFinal = { ...this.filter };
      }
      this.weekTimeout = setTimeout(async () => {
        this.weekQuery = new AbortController();
        const signal = this.weekQuery.signal;
        this.activeWeeks = await this.$API.getActiveWeeks({
          params: filterFinal,
          signal,
        });
        this.loadingWeeks = false;
        this.weekKey++;
      }, 500);
      //   this.loadingActiveWeeks = false;
    },
    async getActiveVessels() {
      this.loadingActiveVessels = true;
      this.activeVessels = await this.$API.getActiveVessels();
      sessionStorage.setItem(
        "activeVessels_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.activeVessels)
      );
      this.loadingActiveVessels = false;
    },
    async getRelations() {
      this.loadingRelatedOrganisations = true;
      if (
        sessionStorage.getItem(
          "relatedOrganisations_" + this.$store.state.currentOrg.id
        )
      ) {
        this.related = JSON.parse(
          sessionStorage.getItem(
            "relatedOrganisations_" + this.$store.state.currentOrg.id
          )
        );
      }
      this.related = await this.$API.getRelationBasic({});
      // let findCurrentOrg = this.related.data.find(
      //   (x) => x.relatedOrganisationId == this.$store.state.currentOrg.id
      // );
      // if (!findCurrentOrg) {
      //   this.related.data.unshift({
      //     relatedOrganisationId: this.$store.state.currentOrg.id,
      //     relatedOrganisation: this.$store.state.currentOrg,
      //   });
      // }
      sessionStorage.setItem(
        "relatedOrganisations_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.related)
      );
      this.loadingRelatedOrganisations = false;
    },
    async getTeams() {
      try {
        this.loadingTeams = true;
        this.teams = await this.$API.getTeams();
        this.loadingTeams = false;
        sessionStorage.setItem(
          "teams_" + this.$store.state.currentOrg.id,
          JSON.stringify(this.teams)
        );
      } catch (error) {
        console.log(error);
      }
    },
    async getUserPerformance() {
      if (this.loadingUserPerformance) {
        clearTimeout(this.weekTimeout);
      }
      if (this.loadingUserPerformance && this.userQuery) {
        this.userQuery.abort();
      }
      this.loadingUserPerformance = true;
      let filterFinal = {};
      // let {vesselVoyage, ...filterVessel} = this.filter
      if (
        this.filter &&
        this.filter.vesselVoyage &&
        this.filter.vesselVoyage?.voyage
      ) {
        filterFinal = {
          ...this.filter,
          vesselVoyage: {
            voyage: this.filter.vesselVoyage.voyage,
            id: this.filter.vesselVoyage.id,
          },
        };
      } else {
        filterFinal = { ...this.filter };
      }
      this.userTimeout = setTimeout(async () => {
        this.userQuery = new AbortController();
        const signal = this.userQuery.signal;
        this.userPerformance =
          await this.$API.getDocumentDashboardUserPerformance({
            params: filterFinal,
            signal,
          });
        this.overallMetrics.OVERDUE = this.userPerformance.reduce(
          (acc, x) => acc + x.OVERDUE,
          0
        );
        this.overallMetrics.TOTAL = this.userPerformance.reduce(
          (acc, x) => acc + x.TOTAL,
          0
        );
        this.overallMetrics.PERFORMANCE =
          100 -
          Math.round(
            (this.overallMetrics.OVERDUE / this.overallMetrics.TOTAL) * 100
          );
        this.overallMetrics.RISK = Math.round(
          this.userPerformance.reduce((acc, x) => acc + x.risk, 0) /
            this.userPerformance.length
        );
        let totalWeight = 0;
        let weightedRiskSum = 0;
        this.userPerformance.forEach((user) => {
          const weight = user.TOTAL; // Use TOTAL documents as the weight
          weightedRiskSum += user.risk * weight;
          totalWeight += weight;
        });

        this.overallMetrics.WEIGHTED_RISK = Math.round(
          weightedRiskSum / totalWeight
        );

        this.loadingUserPerformance = false;
      });
      // console.log(result);
    },
    async getUsers() {
      this.loadingUsers = true;
      let result = await this.$API.getOrganisationUsers();
      result = result.map((x) => ({ ...x.user, position: x.position }));
      result.sort((a, b) => a.firstname.localeCompare(b.firstname));
      this.users = result;
      sessionStorage.setItem(
        "users_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.users)
      );
      this.loadingUsers = false;
    },
    updateTotals(totals) {
      this.totals = totals;
      this.totalRefreshKey++;
    },
  },
};
</script>